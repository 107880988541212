import { memo } from 'react';
import {
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

export const SearchResultTable = memo(({data}:{data: any}) => {
  const title = data.title + " - " + data.company + ": Brands:";
  const brandLists = data.brandLists;
  return (
      <VStack spacing={6}>
        <Text>{title}</Text>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Brand Found</Th>
                <Th># of times found on the company site</Th>
                <Th>Type</Th>
              </Tr>
            </Thead>
            <Tbody>
              {brandLists.map((brand:any, index:any) =>
                <Tr key={index}>
                  <Td>{brand.term}</Td>
                  <Td>{brand.count}</Td>
                  <Td>{index===0?'Primary':'Sub-Brand'}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
  )
})
