import { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
// import { companiesState, selectedCompanySelector } from '../state';
import { Pub } from '../types';
import { useApiActions } from './api-actions';

export const usePubActions = () => {
  // const navigate = useNavigate();
  // const [companies, setCompanies] = useRecoilState(companiesState);
  // const [selectedCompany, setSelectedCompany] = useRecoilState(selectedCompanySelector);
  const { executeApiAction } = useApiActions();

  // const getProject = async (id: string) =>
  //   await executeApiAction<Project>({
  //     action: () => ApiClient.getProject(id),
  //     onError: () => navigate('/'),
  //     errorMessage: 'Failed to load project, refresh to try again',
  //   });

  const createPub = async ({ url, projectId, name }: { url: string, projectId: any, name: string }) =>
    await executeApiAction({
      action: () => ApiClient.createPub({ url, projectId, name }),
      onSuccess: (pub: Pub) => {
        // setCompanies([...companies, company]);
        // setSelectedCompany(company);
        // navigate(`/projects/${project.id}/dashboard`);
      },
      errorMessage: 'Failed to create pub',
    //   successMessage: 'Pub created',
    });



  return {
    // getProject: useCallback(getProject, [executeApiAction, navigate]),
    createPub: useCallback(createPub, [executeApiAction]),
  };
};
