import { memo } from 'react';
import { useRecoilState } from 'recoil';
import {
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Switch,
  Checkbox,
  Input
} from '@chakra-ui/react'

import { brandState } from '../../state';




export const CurationTable = memo(({currentStep}:{currentStep: any}) => {
  const [results, setResults] = useRecoilState(brandState);
  console.log("cureated reusut", results);
  const title = results[currentStep].title + " - " + results[currentStep].company + ": Brands:";
  let brandLists = results[currentStep].brandLists;
  const handleIncludeChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const newResults = results.map((r, i) => i !== currentStep ? r : {
      ...r,
      brandLists: r.brandLists.map((b, j) => j !== index ? b : {...b, include: !b.include}),
    })
    setResults(newResults);
  }
  const handleRecommendChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const newResults = results.map((r, i) => i !== currentStep ? r : {
      ...r,
      brandLists: r.brandLists.map((b, j) => j !== index ? b : {...b, recommend: !b.recommend}),
    })
    setResults(newResults);
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    const newResults = results.map((r, i) => i !== currentStep ? r : {
      ...r,
      brandLists: r.brandLists.map((b, j) => j !== index ? b : {...b, alternative: e.target.value}),
    })
    setResults(newResults);
  }
  return (
      <VStack spacing={6}>
        <Text>{title}</Text>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Brand Found</Th>
                <Th># of times found on the company site</Th>
                <Th>Type</Th>
                <Th>Use Alternative</Th>
                <Th>Recommended Alternative</Th>
                <Th>Include</Th>
              </Tr>
            </Thead>
            <Tbody>
              {brandLists.map((brand:any, index:any) =>
                <Tr key={index}>
                  <Td>{brand.term}</Td>
                  <Td>{brand.count}</Td>
                  <Td>{index===0?'Primary':'Sub-Brand'}</Td>
                  <Td><Checkbox size='lg' colorScheme='green' onChange={(e) => handleRecommendChange(e, index)} isChecked={brand.recommend}/></Td>
                  <Td><Input size='lg' onChange={(e) => handleInputChange(e, index)}  value={brand.alternative}></Input></Td>
                  <Td><Switch size='lg' onChange={(e) => handleIncludeChange(e, index)} isChecked={brand.include}/></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
  )
})
