import { memo } from 'react';
import { Stack, FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react';
import { selectedProjectSelector } from '../../state';
import { useRecoilValue } from 'recoil';


export const InputForm = memo(({
  onInputChange, 
  data
}: {
  onInputChange: (...args: any[]) => void;
  data: any;
}) => {
  const selectedProject = useRecoilValue(selectedProjectSelector);
  return (
    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
      {data.id===0?
      <FormControl id="company">
        <FormLabel>{data.title}</FormLabel>   
        { //@ts-ignore
        <Input index={data.id} placeholder="Company Name" value={selectedProject?.name} onChange={onInputChange} name='company' readOnly />
        }
      </FormControl>
      :
      <FormControl id="company">
        <FormLabel>{data.title}</FormLabel>   
        { //@ts-ignore
        <Input index={data.id} placeholder="Company Name" onChange={onInputChange} name='company'/>
        }
      </FormControl>
      }
      <FormControl id="brand">
        <FormLabel>
          <Tooltip hasArrow label="For 'Core Brand Name', please use the shortest unique string, for example 'Dell', rather than 'The Dell Company'">Core Brand Name</Tooltip>
        </FormLabel>
        { //@ts-ignore
        <Input index={data.id} placeholder="Brand Name" onChange={onInputChange} name='brand' />
        }
      </FormControl>
      <FormControl id="website">
        <FormLabel>Website</FormLabel>
        { //@ts-ignore
        <Input index={data.id} placeholder="www.example.com" onChange={onInputChange} name='website' />
        }
      </FormControl>
    </Stack>
  )
})
