import { Heading, Icon, Box, SimpleGrid, TableContainer, Table, Thead, Tr, Th, Tbody, Td, useColorModeValue, HStack, Stack, Text, Card, CardBody } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { MdAdd, MdWork } from 'react-icons/md';

import { AuthenticatedLayout } from '../layouts';
import { selectedProjectSelector, currentUserState, projectsState } from '../state';
import { Project } from '../types';
import { InactiveProjectDialog } from '../components/projects/InactiveProjectDialog';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';

const cardStyle = {
  minHeight: '180px',
  cursor: 'pointer',
};

export const ProjectListPage: React.FC = () => {
  const navigate = useNavigate();
  const [inactiveProjectId, setInactiveProjectId] = useState<string | null>(null);
  const setSelectedProject = useSetRecoilState(selectedProjectSelector);
  const projects: Project[] = useRecoilValue(projectsState);
  const user = useRecoilValue(currentUserState);
  let sortedProjects = [...projects];
  sortedProjects = sortedProjects.sort((a:Project, b:Project) => (a.created_at < b.created_at)? 1 : (a.created_at === b.created_at) ? 1 : -1);

  useEffect(() => {
    if (projects.length === 0) {
      navigate('/projects/create');
    }
  }, [projects, navigate]);

  const onSelectProject = (project: Project) => {
    setSelectedProject(project);
    navigate(`/projects/${project.id}/dashboard`);
  };

  const ProjectCard = ({ project }: { project: Project }) => (
    <SoftCard
      key={project.id}
      style={{ ...cardStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      onClick={() =>
        project.admin.has_active_subscription ? onSelectProject(project) : setInactiveProjectId(project.id)
      }
    >
      <Box textAlign={['center', 'left']}>
        <Heading size="md">{project.name}</Heading>
        <Heading size="sm">{new Date(project.created_at).toLocaleDateString()}</Heading>
        {/* <Badge variant={project.admin.has_active_subscription ? 'solid' : 'subtle'}>
          {project.admin.has_active_subscription ? 'Active' : 'Inactive'}
        </Badge> */}
      </Box>
      {/* <Box display="flex" flexDir="row">
        <Icon as={MdGroup} boxSize="24px" />
        <Text ml="2">{project.users?.length}</Text>
      </Box> */}
      {user && !project.admin.has_active_subscription && (
        <InactiveProjectDialog
          isOpen={inactiveProjectId === project.id}
          onClose={() => setInactiveProjectId(null)}
          project={project}
          user={user}
        />
      )}
    </SoftCard>
  );

  const backgroundHover = useColorModeValue('white', 'gray.700');
  const background = useColorModeValue('gray.100', 'gray.800');
  
  return (
    <AuthenticatedLayout sidebar={false}>
      <PageHeading title="My Brand Analysis" icon={MdWork} />
      <Box mt={8} />
      {/* <HStack width="100%" spacing={6}> */}
        {/* <SoftCard
          w="25%"
          onClick={() => navigate('/projects/create')}
          style={{
            ...cardStyle,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon boxSize="48px" as={MdAdd} />
          <Heading as="h3" size="sm" textAlign="center">
            Create New Brand Analysis
          </Heading>
        </SoftCard> */}
        {user && sortedProjects && 
        // projects.map((project) => <ProjectCard key={project.id} project={project} />)
        <SoftCard w="100%">
        <Stack spacing={5}>
          <Box px={{ base: '4', md: '6' }} pt="5">
            <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
              <Text fontSize="xl" fontWeight="medium" paddingTop='18px'>
                Brand Analysis List
              </Text>
              <Card 
              _hover={{ background: backgroundHover }}
              boxShadow="lg"
              borderRadius="lg"
              cursor='pointer'
              >
                <CardBody>
                <HStack
                  onClick={() => navigate('/projects/create')}
                  // style={{
                  //   ...cardStyle,
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   alignItems: 'center',
                  //   justifyContent: 'center',
                  // }}
                >
                  <Icon boxSize="24px" as={MdAdd} />
                  <Text fontSize='xl' align="center">
                    Create New Brand Analysis
                  </Text>
                </HStack>
                </CardBody>
              </Card>
            </Stack>
          </Box>
          <Box overflowX="auto">
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Primary Company</Th>
                    <Th>Date Created</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedProjects.map((project:Project, index:number) =>
                    <Tr 
                      key={index} 
                      onClick={() => onSelectProject(project)}
                      _hover={ {background: backgroundHover }}
                      background={background}
                      cursor='pointer'
                    >
                      <Td>{project.name}</Td>
                      <Td>{new Date(project.created_at).toLocaleDateString()}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
        
        </SoftCard>
        }
      {/* </HStack> */}
    </AuthenticatedLayout>
  );
};
