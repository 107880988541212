import { Box, Container, Stack, VStack, Center, Text, HStack } from '@chakra-ui/react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { MdFilterAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { Step, useStep } from '../components/step';
import { CurationTable } from '../components/curate/CurationTable';
import { NextButton } from '../components/forms';
import { selectedProjectSelector, brandState, companiesState } from '../state';
import { useCompanyActions, useBrandActions } from '../hooks';
import { Brand, Company } from '../types';

export interface StepType {
  title: string;
  description: string;
}

export const CurateBrandsPage = () => {

  const { createCompany } = useCompanyActions();
  const { createBrand, createBrands } = useBrandActions();

  const navigate = useNavigate();
  const selectedProject = useRecoilValue(selectedProjectSelector);
  // const location = useLocation();
	// const results: any = location.state;
  const results: any = useRecoilValue(brandState);
  let steps: Array<StepType>= [];
  results.map((result: any) => {
    steps.push({"title": result.title.replace(' Name', ''), "description": result.company});
  })
  const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 0 });
  // const [companies, setCompanies] = useRecoilState(companiesState);
  // const updatedCompanies: Company[] = results.map((data: any) => {
  //   return {name: data.company, url: data.website, brand: data.brand, projectId: selectedProject? selectedProject.id: '', type: data.title==="Your Company Name"?"main":"competitor"};
  // });
  // setCompanies(updatedCompanies);
  const ButtonText = currentStep < steps.length - 1 ? "Save | Next Company" : "Save";
  const saveAndNext = async (currentStep: any) => {
    console.log("data should be saved", results[currentStep]);
    const data = results[currentStep];
    const company: any = await createCompany({name: data.company, url: data.website, brand: data.brand, projectId: selectedProject? selectedProject.id: '', type: data.title==="Your Company Name"?"main":"competitor"});
    console.log("company data", company)
    const inputBrands: Brand[] = data.brandLists.map((brand:any) => {
      if (brand.recommend)
        return {term: brand.alternative, count: brand.count, confidence: brand.confidence, include: brand.include, trends: brand.trends, linkedin: brand.linkedinCount, companyId: company.id}
      return {term: brand.term, count: brand.count, confidence: brand.confidence, include: brand.include, trends: brand.trends, linkedin: brand.linkedinCount, companyId: company.id}
    })
    console.log("inputBrands", inputBrands);
    // await Promise.all(data.brandLists.map(async (brand: any) => {
    //   await createBrand({term: brand.term, count: brand.count, confidence: brand.confidence, include: brand.include, companyId: company.id})
    // }));
    await createBrands(inputBrands);

    if(steps.length > currentStep)
      setStep(currentStep+1);
  }
  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Curate Brands" icon={MdFilterAlt} />
          <Box bg="inherit">
            <Container py={{ base: '4', md: '8' }}>
              <Stack spacing="0" direction={{ base: 'column', md: 'row' }}>
                {steps.map((step, id) => (
                  <Step
                    key={id}
                    cursor="pointer"
                    onClick={() => setStep(id)}
                    title={step.title}
                    description={step.description}
                    isActive={currentStep === id}
                    isCompleted={currentStep > id}
                    isFirstStep={id === 0}
                    isLastStep={steps.length === id + 1}
                  />
                ))}
              </Stack>
            </Container>
          </Box>
        </SoftCard>
        <SoftCard w="100%">
          {currentStep < steps.length ?
          <CurationTable key={currentStep} currentStep={currentStep}></CurationTable>
          :
          <Text>Curation Completed!</Text>
          }
        </SoftCard>
        {currentStep === 0?
        <Center w="100%">
          <NextButton text={ButtonText} onSubmit={()=>saveAndNext(currentStep)} isLoaded={true}></NextButton>
        </Center>
        : currentStep !==steps.length?
        <Center w="100%">
          <HStack spacing={2}>
            <NextButton text="Previous" onSubmit={()=>{setStep(currentStep-1)}} isLoaded={true}></NextButton>
            <NextButton text={ButtonText} onSubmit={()=>saveAndNext(currentStep)} isLoaded={true}></NextButton>
          </HStack>
        </Center>
        :
        <Center w="100%">
          <HStack spacing={2}>
            <NextButton text="Previous" onSubmit={()=>{setStep(currentStep-1)}} isLoaded={true}></NextButton>
            <NextButton text="Pub Search" onSubmit={()=>{navigate(selectedProject ? `/projects/${selectedProject.id}/pub/pending` : '/');}} isLoaded={true}></NextButton>
          </HStack>
        </Center>
        }
      </VStack>
    </AuthenticatedLayout>
  )
}
