import { useEffect, useState } from 'react';
import { Text, VStack, Center, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Flex, Spinner, TableCaption } from '@chakra-ui/react';
import { MdInput } from 'react-icons/md';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { InputForm, NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout, DefaultLayout } from '../layouts';
import { currentUserState, selectedProjectSelector, companiesState, brandsState, companiesArrayState } from '../state';
import { ApiClient } from '../lib/clients/api-client';

const dateTypeFormat = (dateString: string) => {
  const [month_day, year] = dateString.split('_');
  const month = month_day.slice(0,3);
  const day = month_day.slice(3);
  const date = new Date(Number(year), new Date(Date.parse(`${month} 1, 2000`)).getMonth(), Number(day));
  const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return formattedDate;
}
export const SetupCompanyPage: React.FC = () => {
  const user = useRecoilValue(currentUserState);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const [isLoaded, setIsLoaded] = useState(false)
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [trends, setTrends] = useState<any[]>([]);
  // const companyName: string = selectedProject?.name || '';
  // const companies = useRecoilValue(companiesState);
  // console.log("companies", companies)
  // const brands = useRecoilValue(brandsState);
  // console.log("brands", brands)
  // if(!companies.length) {
  //   console.log("need to setup")
  // }
  const fetchData = async () => {
    if(selectedProject) {
      const companies:any = await ApiClient.getCompanies(selectedProject.id);
      console.log("companies", companies)
      let brandsList: any = [];
      let trendsList: any = [];
      await Promise.all(companies.map(async(company:any)=>{
        let brands = await ApiClient.getBrands(company.id);
        brands = brands.sort((a:any, b:any) => (a.count < b.count)? 1 : (a.count === b.count) ? 1 : -1);
        brands = brands.sort((a: any, b: any)=> (a.confidence==='primary')? -1: (b.confidence==='primary')? 1: 0)
        let trends
        if (brands[0]?.trends)
         trends = Object.entries(brands[0].trends);
        console.log("brands", brands)
        console.log('trendslist', trends)
        brandsList.push(brands);
        trendsList.push(trends);
      }))
      brandsList = brandsList.sort((a:any, b:any) => (a[0]?.company?.type === 'main')? -1: (b[0]?.company?.type === 'main') ? 1: 0)
      console.log("brandslist", brandsList)
      console.log('trends', trendsList)
      setBrands(brandsList);
      setTrends(trendsList);
      setCompanies(companies);
      setIsLoaded(true);
      return companies
    }
  }
  useEffect(() => {
    fetchData();
  }, [])
  const navigate = useNavigate();  

  const [arrays, setArrays] = useRecoilState(companiesArrayState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const { name, value } = e.target;
    const index = e.target.getAttribute('index');
    const updatedArrays = arrays.map((values, i) => {
      if (i === Number(index)) {
        return {...values, [name]:value};
      }
      else {
        return values;
      }
    });
    setArrays(updatedArrays);
  };

  const handleClick = () => {
    // navigate(selectedProject ? `/projects/${selectedProject.id}/search/pending` : '/');
    navigate(selectedProject ? `/projects/${selectedProject.id}/pub/input` : '/');
  }

  return (
    <>
      {!isLoaded?
      <DefaultLayout>
        <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      </DefaultLayout>
      :!companies.length?
      <AuthenticatedLayout>
        <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Companies" icon={MdInput} />
          <Text mt={6}>{`Welcome, ${user?.first_name} 👋`}</Text>
          <Text> Please setup Company Information here. </Text>
          <Text> For "Core Brand Name", please use the shortest unique string, for example "Dell", rather than "The Dell Company".</Text>
        </SoftCard>
        {arrays.map(values => 
          <SoftCard w="100%" key={values.id}>
            <InputForm onInputChange={handleInputChange} data={values}></InputForm>
          </SoftCard>
          )}
        <Center w="100%">
          <NextButton text='Submit for Processing' onSubmit={handleClick} isLoaded={true}></NextButton>
        </Center>
      </VStack>
      </AuthenticatedLayout>
      :
      <AuthenticatedLayout>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Companies" icon={MdInput} />
          <Text mt={6}>{`Welcome back, ${user?.first_name} 👋`}</Text>
          <Text>  You've already setup companies and got results.</Text>
        </SoftCard>
        {brands.map((brandList:any, index:number)=> 
        <SoftCard key={index} w="100%">
          <VStack spacing={6}>
            <Text>Company: {brandList[0]?.company?.name.toUpperCase()} &nbsp; &nbsp;   Brand: {brandList[0]?.company?.brand.toUpperCase()}</Text>
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Project</Th>
                    <Th>Company</Th>
                    <Th>Term</Th>
                    <Th>Type</Th>
                    <Th>Site Hits</Th>
                    <Th>Web Hits</Th>
                    <Th>Linkedin Hits</Th>
                    <Th>Pub1 Hits</Th>
                    <Th>Pub2 Hits</Th>
                    <Th>Pub3 Hits</Th>
                    <Th>Pub4 Hits</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {brandList.map((brand:any, index:any) =>
                    <Tr key={index}>
                      <Td>{selectedProject?.name.toUpperCase()}</Td>
                      <Td>{brand.company?.name.toUpperCase()}</Td>
                      <Td>{brand.term}</Td>
                      <Td>{brand.confidence}</Td>
                      <Td>{brand.count}</Td>
                      <Td>{brand.webhits}</Td>
                      <Td>{brand.linkedin}</Td>
                      <Td>{brand.pub1}</Td>
                      <Td>{brand.pub2}</Td>
                      <Td>{brand.pub3}</Td>
                      <Td>{brand.pub4}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            {trends[0] !== undefined?
              <TableContainer>
                <Table variant='simple'>
                  <TableCaption placement='top'>12 Months Trend</TableCaption>
                  <Thead>
                    <Tr>
                      {trends[index].map((trend: any, i: any) => 
                          <Th key={i}>{i>0?dateTypeFormat(trend[0]):trend[0]}</Th>
                        )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      {trends[index].map((trend: any, i: any) => 
                          <Td key={i}>{trend[1]}</Td>
                      )}
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            :
            <></>
            }
            
          </VStack>
        </SoftCard>
        )}
      </VStack>
      </AuthenticatedLayout>
      }
    </>
  );
};
