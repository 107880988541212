import { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
// import { companiesState, selectedCompanySelector } from '../state';
import { Company } from '../types';
import { useApiActions } from './api-actions';

export const useCompanyActions = () => {
  // const navigate = useNavigate();
  // const [companies, setCompanies] = useRecoilState(companiesState);
  // const [selectedCompany, setSelectedCompany] = useRecoilState(selectedCompanySelector);
  const { executeApiAction } = useApiActions();

  // const getProject = async (id: string) =>
  //   await executeApiAction<Project>({
  //     action: () => ApiClient.getProject(id),
  //     onError: () => navigate('/'),
  //     errorMessage: 'Failed to load project, refresh to try again',
  //   });

  const createCompany = async ({ name, url, brand, projectId, type }: { name: string, url: string, brand: string, projectId: any, type: string }) =>
    await executeApiAction({
      action: () => ApiClient.createCompany({ name, url, brand, projectId, type }),
      onSuccess: (company: Company) => {
        // setCompanies([...companies, company]);
        // setSelectedCompany(company);
        // navigate(`/projects/${project.id}/dashboard`);
      },
      errorMessage: 'Failed to create company',
      successMessage: 'Company created',
    });



  return {
    // getProject: useCallback(getProject, [executeApiAction, navigate]),
    createCompany: useCallback(createCompany, [executeApiAction]),
  };
};
