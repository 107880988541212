import { memo, useState, useRef, useEffect } from 'react';
import { Text, VStack, Center, Card, CardHeader, CardBody, CardFooter, HStack, Box, Progress } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { MdPending } from 'react-icons/md';
import { useTimer } from 'react-timer-hook';

import { NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { selectedProjectSelector, companiesState, pubsState, brandsState } from '../state';
import { useBrandActions } from '../hooks';
import { Brand } from '../types';
import { ApiClient } from '../lib/clients/api-client';

export const PubPendingPage = memo(() => {
  
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 5 minutes timer

  const {
    seconds,
    minutes,
  } = useTimer({ expiryTimestamp:time });

  const secondDigitOne = seconds%10;
  const secondDigitTwo = (seconds - secondDigitOne)/10;
  const minuteDigitOne = minutes%10;
  const minuteDigitTwo = (minutes - minuteDigitOne)/10;
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const dataFetchedRef = useRef(false);

  const { updateBrands } = useBrandActions();
  const companies = useRecoilValue(companiesState);
  // console.log("companies",companies)
  // const company = companies.filter(company=>company.type==="main")[0];
  // const companyUrl = company.url;
  // const companyBrand = company.brand;
  // console.log("my company", company);

  // let brands = useRecoilValue(brandsState);
  // let updatedBrands: Brand[] = [];
  // // console.log("main brands", brands);
  // let brandTermArray;
  // // brands = brands.filter( brand=> brand.term!==companyBrand)
  // if (brands.length>10)
  //   brands = brands.slice(0,10);
  // if(brands) {
  //   brandTermArray = brands.map(brand=>brand.term);
  //   updatedBrands = brands;
  // }
  // console.log("brandterm", brands, "array", brandTermArray)
  const pubs = useRecoilValue(pubsState);
  // console.log("pubs", pubs);

  // Generate Query String of Internet Search from the brand list and company url
  // let internetQuery: string[][] = [[]];
  // const regex = /[®™]/g;
  // if (brands)
  //   brands.forEach((brand, index) => {
  //     // if (term!==companyBrand)
  //       internetQuery[index] = [];
  //       // internetQuery[index] = `"${term}" and "${companyBrand}" -inurl:${companyUrl}\n`
  //       if(brand.term === brand.company.brand){
  //         // console.log("brand",companyBrand)
  //         internetQuery[index][0] = brand.company.brand;
  //       }
  //       else 
  //         internetQuery[index][0] = `"${brand.term.replace(regex, '')}" and "${brand.company.brand}" -site:${brand.company.url}\n`
  //       pubs.forEach((pub, i)=>{
  //         internetQuery[index][i+1] = `"${brand.term.replace(regex, '')}" site:${pub.url}\n`
  //       })
      
  //   })
  // if (internetQuery)
    // console.log("query", internetQuery)

  const navigate = useNavigate();

  const API_URL = 'https://baabrandsearch1.herokuapp.com/api/brands'
  // process.env.NODE_ENV === "production"
  //   ? "https://baabrandsearch1.herokuapp.com/api/brands"
  //   : "http://localhost:80/api/brands";
  // const API_URL = process.env.REACT_APP_API_URL;
  // const pubValues: any = useRecoilValue(inputPubState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [results, setResults] = useState<Number[][]>([]);

  const apiUrl = "https://api.apify.com/v2/acts/parse~google-search/run-sync-get-dataset-items?token=apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1"

  const fetchData = async () => {
    try {
      let brands: Brand[] = [];
      await Promise.all(companies.map(async(company:any)=>{
        let companyBrands = await ApiClient.getBrands(company.id);
        // brandsList.push(brands);
        brands = [...brands, ...companyBrands]
      }))
      console.log("asdfasdf", brands)
      let updatedBrands: Brand[] = brands;
      let internetQuery: string[][] = [[]];
      const regex = /[®™]/g;
      if (brands)
        brands.forEach((brand, index) => {
          // if (term!==companyBrand)
            internetQuery[index] = [];
            // internetQuery[index] = `"${term}" and "${companyBrand}" -inurl:${companyUrl}\n`
            if(brand.term === brand.company.brand){
              // console.log("brand",companyBrand)
              internetQuery[index][0] = `"${brand.company.brand}" -site:${brand.company.url}`;
            }
            else 
              internetQuery[index][0] = `"${brand.term.replace(regex, '')}" and "${brand.company.brand}" -site:${brand.company.url}`
            pubs.forEach((pub, i)=>{
              internetQuery[index][i+1] = `"${brand.term.replace(regex, '')}" site:${pub.url}`
            })
          
        })
      
      let responses: any[][] = [];
      console.log("query", internetQuery)
      for(let index=0; index<internetQuery.length; index++)
      {
        let totalNumbers: number[] = [];
        responses[index] = [];
        for(let i=0; i<internetQuery[index].length; i++) {
          responses[index][i] = await axios.post(apiUrl, {query: internetQuery[index][i]});
          console.log("response", responses[index][i])
          const number = responses[index][i].data[0].search_result_count;
          totalNumbers.push(number);
        }
        console.log("numbers", totalNumbers);
        results.push(totalNumbers);
      }
      results.map((totalResults:any) => {
        const total = totalResults.sort((a:any, b:any) => (a < b)? 1 : (a === b) ? 1 : -1); 
        return total;
      });
      updatedBrands = updatedBrands.map((updatedBrand: any, index: number) => {
        const brand = { ...updatedBrand, 'webhits':results[index][0], 'pub1': pubs.length > 0? results[index][1]: 0, 'pub2': pubs.length > 1? results[index][2]: 0, 'pub3': pubs.length > 2? results[index][2]: 0, 'pub4': pubs.length > 3? results[index][3]: 0};
        console.log('updatedBrand', brand);
        return brand;
      });
      console.log("updatedBrands", updatedBrands);
      await updateBrands(updatedBrands);
    } catch(err) {
      console.log(err)
    }
    // await axios.post(API_URL, results)
    // await updateBrands(updatedBrands);
    setIsLoaded(true);
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
  }, []);
  
  const handleClick = async () => {
    navigate(selectedProject ? `/projects/${selectedProject?.id}/dashboard` : '/', {state:results});
  }
  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Pubs: Confirmation" icon={MdPending} />
        </SoftCard>
        {isLoaded?
        <Center w="100%" h="h-[calc(100vh-80px)]">
          <NextButton text='Pub Search Complete - To Dashboard' isLoaded={isLoaded} onSubmit={handleClick}></NextButton>
        </Center>
        :
        <Center w="100%" h="h-[calc(100vh-80px)]">
          <VStack spacing={6}>
          <Card align='center'>
            <CardHeader>
            <HStack>
              <Center w='40px' h='40px' bg='tomato' color='white'>
                <Box as='span' fontWeight='bold' fontSize='lg'>
                  {minuteDigitTwo}
                </Box>
              </Center>
              <Center w='40px' h='40px' bg='tomato' color='white'>
                <Box as='span' fontWeight='bold' fontSize='lg'>
                  {minuteDigitOne}
                </Box>
              </Center>
              <Text> : </Text>
              <Center w='40px' h='40px' bg='tomato' color='white'>
                <Box as='span' fontWeight='bold' fontSize='lg'>
                  {secondDigitTwo}
                </Box>
              </Center>
              <Center w='40px' h='40px' bg='tomato' color='white'>
                <Box as='span' fontWeight='bold' fontSize='lg'>
                  {secondDigitOne}
                </Box>
              </Center>
            </HStack>
            </CardHeader>
            <CardBody>
              <Progress size='xs' isIndeterminate></Progress>
              <Text mt={5}>Pubs submitted. We are now getting the google search results based Pub websites you have entered.
                  This may take up to five minutes.</Text>
            </CardBody>
            <CardFooter>
            <NextButton text='Currently Searching..' isLoaded={isLoaded} onSubmit={handleClick}></NextButton>
            </CardFooter>
          </Card>
          </VStack>
        </Center>
        }
      </VStack>
    </AuthenticatedLayout>
  )
})
