import { useTimer } from 'react-timer-hook';
import { Text, Center, HStack, Box } from '@chakra-ui/react';

export const MyTimer = ({ expiryTimestamp }: {expiryTimestamp: any}) => {
    const {
      seconds,
      minutes,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
    const secondDigitOne = seconds%10;
    const secondDigitTwo = (seconds - secondDigitOne)/10;
    const minuteDigitOne = minutes%10;
    const minuteDigitTwo = (minutes - minuteDigitOne)/10;
  
    return (
      <HStack>
        <Center w='40px' h='40px' bg='tomato' color='white'>
          <Box as='span' fontWeight='bold' fontSize='lg'>
            {minuteDigitTwo}
          </Box>
        </Center>
        <Center w='40px' h='40px' bg='tomato' color='white'>
          <Box as='span' fontWeight='bold' fontSize='lg'>
            {minuteDigitOne}
          </Box>
        </Center>
        <Text> : </Text>
        <Center w='40px' h='40px' bg='tomato' color='white'>
          <Box as='span' fontWeight='bold' fontSize='lg'>
            {secondDigitTwo}
          </Box>
        </Center>
        <Center w='40px' h='40px' bg='tomato' color='white'>
          <Box as='span' fontWeight='bold' fontSize='lg'>
            {secondDigitOne}
          </Box>
        </Center>
      </HStack>
    );
  }