import { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
// import { projectsState, selectedProjectSelector } from '../state';
import { Brand } from '../types';
import { useApiActions } from './api-actions';

interface trendData {
  [date: string]: number;
}

export const useBrandActions = () => {
  // const navigate = useNavigate();
  // const [projects, setProjects] = useRecoilState(projectsState);
  // const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectSelector);
  const { executeApiAction } = useApiActions();

  // const getProject = async (id: string) =>
  //   await executeApiAction<Project>({
  //     action: () => ApiClient.getProject(id),
  //     onError: () => navigate('/'),
  //     errorMessage: 'Failed to load project, refresh to try again',
  //   });

  const createBrand = async ({ term, count, confidence, include, trends, linkedin, companyId }: { term: string, count: number, confidence: string, include: boolean, trends: trendData, linkedin: number, companyId: string }) =>
    await executeApiAction({
      action: () => ApiClient.createBrand({ term, count, confidence, include, trends, linkedin, companyId }),
    //   errorMessage: 'Failed to create brand',
    //   successMessage: 'Brand created',
    });

  const createBrands = async (inputBrands: Brand[]) =>
    await executeApiAction({
      action: () => ApiClient.createBrands(inputBrands),
    errorMessage: 'Failed to create brands',
    successMessage: 'Brands created',
  });

  const updateBrands = async (inputBrands: Brand[]) =>
    await executeApiAction({
      action: () => ApiClient.updateBrands(inputBrands),
      errorMessage: 'Failed to update Brands',
      successMessage: 'Brands updated',
    })

  return {
    // getProject: useCallback(getProject, [executeApiAction, navigate]),
    createBrand: useCallback(createBrand, [executeApiAction]),
    createBrands: useCallback(createBrands, [executeApiAction]),
    updateBrands: useCallback(updateBrands, [executeApiAction]),
  };
};
