import { atom, selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { Pub } from '../types';
import { currentUserState } from './current-user';
import { selectedProjectSelector } from './projects';

const defaultPubsSelector = selector<Pub[]>({
  key: 'pub-list-selector',
  get: async ({ get }) => {
    try {
      const currentUser = get(currentUserState);
      const currentProject = get(selectedProjectSelector);
      if (currentUser && currentProject) {
        return ApiClient.getPubs(currentProject.id);
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  },
});

export const pubsState = atom<Pub[]>({
  key: 'pubs',
  default: defaultPubsSelector,
});
