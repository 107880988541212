import { atom, DefaultValue, selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { Company } from '../types';
import { currentUserState } from './current-user';
import { selectedProjectSelector } from './projects';

const defaultCompaniesSelector = selector<Company[]>({
  key: 'company-list-selector',
  get: async ({ get }) => {
    try {
      const currentUser = get(currentUserState);
      const currentProject = get(selectedProjectSelector);
      if (currentUser && currentProject) {
        return ApiClient.getCompanies(currentProject.id);
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  },
});

export const companiesState = atom<Company[]>({
  key: 'companies',
  default: defaultCompaniesSelector,
});

const selectedCompanyState = atom<string | null>({
  key: 'select-company',
  default: localStorage.getItem('selected_company_id'),
});

export const selectedCompanySelector = selector<Company | null>({
  key: 'selected-company-selector',
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) return;

    if (newValue) {
      localStorage.setItem('selected_company_id', newValue.id);
    } else {
      localStorage.removeItem('selected_company_id');
    }

    set(selectedCompanyState, newValue ? newValue.id : null);
  },
  get: ({ get }) => {
    const companies = get(companiesState);
    const selectedId = get(selectedCompanyState);

    if (!selectedId) {
      return null;
    }
    return companies.find((company) => company.id === selectedId) || null;
  },
});
