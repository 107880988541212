import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  VStack, 
  Center, 
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer, 
} from '@chakra-ui/react';
import { MdTableView } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { selectedProjectSelector, companiesState, brandsState } from '../state';
import { PubResultTable } from '../components/result/PubResultTable';

export const PubResultPage = memo(() => {
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const companies = useRecoilValue(companiesState);
  console.log("companies",companies)
  const company = companies.filter(company=>company.type==="main")[0];
  let brands = useRecoilValue(brandsState);
  // brands = brands.filter( brand=> brand.term!==company.brand)
  // console.log("main brands", brands);
  let brandTermArray: string[];
  if (brands.length>20)
    brands = brands.slice(0,10);
  if(brands)
    brandTermArray = brands.map(brand=>brand.term);
  const navigate = useNavigate();
	const location = useLocation();
	console.log('data', location.state);
	const results: any = location.state;
  // const handleClick = () => {
  //   navigate(selectedProject ? `/projects/${selectedProject.id}/search/curate` : '/', {state:results});
  // }
  // results.map((totalResults:any) => {
  //   const total = totalResults.sort((a:any, b:any) => (a < b)? 1 : (a === b) ? 1 : -1); 
  //   return total;
  // })


  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <HStack w="100%" justify="space-between">
            <PageHeading title="Search Results" icon={MdTableView} />
            {/* <NextButton text='Curate Brands' isLoaded={true} onSubmit={handleClick}></NextButton> */}
          </HStack>
        </SoftCard>
        {/* {results.map((result:any, i:any) =>  */}
        <SoftCard w="100%">
          { brands.length>0?
          <VStack spacing={6}>
            <Text>Total Results</Text>
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Project</Th>
                    <Th>Company</Th>
                    <Th>Term</Th>
                    <Th>Web Hits</Th>
                    <Th>Pub1 Hits</Th>
                    <Th>Pub2 Hits</Th>
                    <Th>Pub3 Hits</Th>
                    <Th>Pub4 Hits</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {results.map((totalResults:any, index:any) => 
                    <Tr key={index}>
                      <Td>{selectedProject?.name}</Td>
                      <Td>{company.name}</Td>
                      <Td>{brandTermArray[index]}</Td>
                      <Td>{totalResults[0]}</Td>
                      <Td>{totalResults[1]}</Td>
                      <Td>{totalResults[2]}</Td>
                      <Td>{totalResults[3]}</Td>
                      <Td>{totalResults[4]}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
          :
          <Text>There are no sub-brands to do pub-search.</Text>
          }
          
          </SoftCard>
          <Center w="100%">
          <NextButton text="To Dashboard" onSubmit={()=>{navigate(`/projects/${selectedProject?.id}/dashboard`);}} isLoaded={true}></NextButton>
          </Center>
        {/* )} */}
      </VStack>
    </AuthenticatedLayout>
  )
})
