import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { VStack, Center, HStack } from '@chakra-ui/react';
import { MdTableView } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { SearchResultTable } from '../components/result/SearchResultTable';
import { selectedProjectSelector } from '../state';

export const SearchResultPage = memo(() => {
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const navigate = useNavigate();
	const location = useLocation();
	console.log('data', location.state);
	const results: any = location.state;
  const handleClick = () => {
    navigate(selectedProject ? `/projects/${selectedProject.id}/search/curate` : '/', {state:results});
  }

  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <HStack w="100%" justify="space-between">
            <PageHeading title="Search Results" icon={MdTableView} />
            <NextButton text='Curate Brands' isLoaded={true} onSubmit={handleClick}></NextButton>
          </HStack>
        </SoftCard>
        {results.map((result:any, i:any) => 
          <SoftCard key={i} w="100%">
            <SearchResultTable data={result}></SearchResultTable>
          </SoftCard>
        )}
      </VStack>
    </AuthenticatedLayout>
  )
})
