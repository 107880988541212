import { useEffect, useState } from 'react';
import { Text, VStack, Center, Stack, FormControl, FormLabel, Input, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Flex, Spinner } from '@chakra-ui/react';
import { MdInput } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout, DefaultLayout } from '../layouts';
import { currentUserState, selectedProjectSelector } from '../state';
import { usePubActions } from '../hooks';
import { ApiClient } from '../lib/clients/api-client';


export const SetupPubsPage: React.FC = () => {

  const { createPub } = usePubActions();
  
  const user = useRecoilValue(currentUserState);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  // const companies:any = await ApiClient.getCompanies(selectedProject.id);
  const [isLoaded, setIsLoaded] = useState(false)
  const [ companies, setCompanies ] = useState<any[]>([]);
  const [ company, setCompany ] = useState<any>();
  const [ pubs, setPubs ] = useState<any[]>([]);
  const [ brands, setBrands ] = useState<any[]>([]);
  // console.log("companies",companies)
  // const company = companies.filter(company=>company.type==="main");

  const navigate = useNavigate();

  const initialValue = 
    {
      pub1: '',
      pub2: '',
      pub3: '',
      pub4: ''
    };

  const [pubValues, setPubValues] = useState(initialValue);

  const fetchData = async () => {
    if(selectedProject) {
      const companies:any = await ApiClient.getCompanies(selectedProject.id);
      const pubs: any = await ApiClient.getPubs(selectedProject.id);
      setCompanies(companies);
      setPubs(pubs);
      const company = companies.filter((company: any)=>company.type==="main")[0];
      let brands: any
      if (company) 
        brands = await ApiClient.getBrands(company.id);
      setBrands(brands);
      setCompany(company);
      setIsLoaded(true);
    }
    return true
  }
  useEffect(() => {
    fetchData();
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const { name, value } = e.target;
    const updatedPubValues = {...pubValues, [name]:value}
    setPubValues(updatedPubValues);
  };

  const handleClick = async () => {
    if (pubValues.pub1) await createPub({url: pubValues.pub1, name: 'pub1', projectId: selectedProject?.id});
    if (pubValues.pub2) await createPub({url: pubValues.pub2, name: 'pub2', projectId: selectedProject?.id});
    if (pubValues.pub3) await createPub({url: pubValues.pub3, name: 'pub3', projectId: selectedProject?.id});
    if (pubValues.pub4) await createPub({url: pubValues.pub4, name: 'pub4', projectId: selectedProject?.id});
    // navigate(selectedProject ? `/projects/${selectedProject.id}/pub/pending` : '/');
    navigate(selectedProject ? `/projects/${selectedProject.id}/search/pending` : '/');
  }

  return (
    <>
    {!isLoaded?
    <DefaultLayout>
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Flex>
    </DefaultLayout>
    
    :!pubs.length?
    <AuthenticatedLayout>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Pubs" icon={MdInput} />
          <Text mt={6}>{`Welcome, ${user?.first_name} 👋`}</Text>
          <Text>  Please setup Pub Information. You are expected to have company brand search completed before moving here.</Text>
        </SoftCard>
        <SoftCard w="100%">
          <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
            <FormControl id="pub1">
                <FormLabel>Pub 1 URL</FormLabel>   
                <Input placeholder="www.example.com" onChange={handleInputChange} name='pub1'/>
            </FormControl>
            <FormControl id="pub2">
                <FormLabel>Pub 2 URL</FormLabel>
                <Input placeholder="www.example.com" onChange={handleInputChange} name='pub2' />
            </FormControl>
            <FormControl id="pub3">
                <FormLabel>Pub 3 URL</FormLabel>
                <Input placeholder="www.example.com" onChange={handleInputChange} name='pub3' />
            </FormControl>
            <FormControl id="pub4">
                <FormLabel>Pub 4 URL</FormLabel>
                <Input placeholder="www.example.com" onChange={handleInputChange} name='pub4' />
            </FormControl>
          </Stack>
        </SoftCard>
        <Center w="100%">
          <NextButton text='Submit for Processing' onSubmit={handleClick} isLoaded={true}></NextButton>
        </Center>
      </VStack>
    </AuthenticatedLayout>
    :
    <AuthenticatedLayout>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Pubs" icon={MdInput} />
          <Text mt={6}>{`Welcome back, ${user?.first_name} 👋`}</Text>
          <Text> You've already setup pubs and got results.</Text>
        </SoftCard>
        <VStack spacing={6}>
          <Text>Total Results</Text>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Project</Th>
                  <Th>Company</Th>
                  <Th>Term</Th>
                  <Th>Web Hits</Th>
                  {pubs.map((pub:any, index:number)=> 
                    <Th key={index}>Pub{index+1} ({pub.url}) Hits</Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {brands.map((brand:any, index:any) => 
                  <Tr key={index}>
                    <Td>{selectedProject?.name}</Td>
                    <Td>{company.name}</Td>
                    <Td>{brand.term}</Td>
                    <Td>{brand.webhits}</Td>
                    {pubs.map((pub:any, index:number)=> 
                    <Td key={index}>{index===0?Number(brand.pub1):index===1?Number(brand.pub2):index===2?Number(brand.pub3):Number(brand.pub4)}</Td>
                  )}
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      </VStack>
    </AuthenticatedLayout>
    }
    </>
  );
};
