import { atom, selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { Brand, Company } from '../types';
import { currentUserState } from './current-user';
import { companiesState } from './companies';

interface Result {
  title: string;
  company: string;
  brand: string;
  brandLists: any[];
}

export const brandState = atom<Result[]>({
  key: 'brands',
  default: [],
});



const defaultBrandsSelector = selector<Brand[]>({
  key: 'brand-list-selector',
  get: async ({ get }) => {
    try {
      const currentUser = get(currentUserState);
      const currentCompanies = get(companiesState);
      let mainCompany;
      if(currentCompanies)
        mainCompany = currentCompanies.filter((company: any)=>company.type==='main')[0];
      if (currentUser && mainCompany) {
        return ApiClient.getBrands(mainCompany.id);
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  },
});

export const brandsState = atom<Brand[]>({
  key: 'main-company-brands',
  default: defaultBrandsSelector,
});