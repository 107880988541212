import { atom, selector } from 'recoil';
import { currentUserState } from './current-user';
import { selectedProjectSelector } from './projects';

interface Company {
  id: number;
  title: string;
  company: string;
  brand: string;
  website: string;
}

const defaultInputCompanyArraySelector = selector<Company[]>({
  key: 'company-array-selector',
  get: async ({ get }) => {
    try {
      const currentUser = get(currentUserState);
      const currentProject = get(selectedProjectSelector);
      if (currentUser && currentProject) {
        const companyName: string = currentProject?.name || '';
        const initialArrays:Company[] = [
          {
            id: 0,
            title: "Your Company Name",
            company: companyName,
            brand: "",
            website: ""
          },
          {
            id: 1,
            title: "Competitor 1 Name",
            company: "",
            brand: "",
            website: ""
          },
          {
            id: 2,
            title: "Competitor 2 Name",
            company: "",
            brand: "",
            website: ""
          }
        ];
        return initialArrays;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  },
});

export const companiesArrayState = atom<Company[]>({
  key: 'companiesArray',
  default: defaultInputCompanyArraySelector,
});