import { memo } from 'react';
import { Stack, Button } from '@chakra-ui/react';

export const NextButton = memo(({
  text, 
  onSubmit,
  isLoaded
}: {
  text: string;
  onSubmit: (...args: any[]) => void;
  isLoaded: boolean;
}) => {
  return (
    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
      <Button colorScheme='teal' variant='outline' onClick={onSubmit} isLoading={!isLoaded} loadingText={text}>
        {text}
      </Button>
    </Stack>
  )
})
