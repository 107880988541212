import React from 'react';
import { Input, Button, Text, Box, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { AuthenticatedLayout } from '../layouts';
import { MdAdd, MdChevronRight, MdPending } from 'react-icons/md';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { useProjectActions } from '../hooks';
import { useRecoilValue } from 'recoil';
import { currentUserState, projectsState } from '../state';
import { Project } from '../types';

type FormValues = {
  name: string;
};

export const CreateProjectPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { createProject } = useProjectActions();
  const projects: Project[] = useRecoilValue(projectsState);
  const user = useRecoilValue(currentUserState);

  const onSubmit = async ({ name }: { name: string }) => {
    await createProject({ name });
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      {projects.length===0?
      <Box mt={8}>
        <PageHeading title={`Congrats ${user?.first_name}! You are registered.`}></PageHeading>
        <Text fontSize="xl" mt={8} mb={4}>
          Let's start your first Brand Analysis..
        </Text>
      </Box>
      :
      <></>
      }
      <Box mt={8}></Box>
      <PageHeading title="Create New Brand Analysis" icon={MdAdd} />
      <Box mt={8} />
      <SoftCard>
        <Text fontSize="lg" mb={4}>
          What is the primary company you want to do run a brand analysis on?
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input size={'lg'} placeholder="Enter your company name" {...register('name', { required: true })} />
          {errors?.name && <p>Name is required</p>}
          <Button size="lg" rightIcon={<MdChevronRight />} type="submit" mt={4}>
            Let's go!
          </Button>
        </form>
      </SoftCard>
    </AuthenticatedLayout>
  );
};
