import { memo, useState, useRef, useEffect } from 'react';
import { Text, VStack, Center, Card, CardHeader, CardBody, CardFooter, HStack, Box, Progress } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import { MdPending } from 'react-icons/md';
import { useSetRecoilState } from 'recoil';

import { MyTimer } from '../components/MyTimer';
import { NextButton } from '../components/forms';
import { PageHeading } from '../components/generic/PageHeading';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout } from '../layouts';
import { selectedProjectSelector, brandState, companiesArrayState } from '../state';
import dictionaryList from './dictionary.json'

interface Result {
  title: string;
  company: string;
  brand: string;
  brandLists: any;
  website: string;
}

type InputData = {
  count: number;
  longTerm: string;
  term: string;
}

type OutputData = {
  term: string;
  count: number;
  isCommon: boolean | null;
  alternative: string | null;
  isMoreThanOne: boolean | null;
  recommend: boolean | null;
}

const apiToken = 'apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1';
const actorId = 'baa1~ingredion';

export const SearchPendingPage = memo(() => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 5 minutes timer
  const setSearchResult = useSetRecoilState(brandState);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const dataFetchedRef = useRef(false);

  const navigate = useNavigate();
  const arrays: any = useRecoilValue(companiesArrayState)
  console.log("companu", arrays)
  const [isLoaded, setIsLoaded] = useState(false);

  function findMostPopularString(arr: string[]) {
    const counts: any = {};
    let mostPopular = null;
    let maxCount = 0;
  
    for (let i = 0; i < arr.length; i++) {
      const str = arr[i];
      counts[str] = (counts[str] || 0) + 1;
      if (counts[str] > maxCount) {
        maxCount = counts[str];
        mostPopular = str;
      }
    }
  
    return mostPopular;
  }

  const [results, setResults] = useState<Result[]>([]);

  const apiUrl = "https://api.apify.com/v2/acts/baa1~ingredion/run-sync-get-dataset-items?token=apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1"

  const fetchData = async () => {

    try {
      let responses: any[] = [];
      for (let index = 0; index<arrays.length; index++) {
        if(arrays[index].website) {
          let input;
          if(!arrays[index].website.includes('https://')) 
            input = {startUrls: [{url: 'https://' + arrays[index].website}]};
            // responses[index] = await axios.post(apiUrl, {startUrls: [{url: 'https://' + arrays[index].website}]});
          else
            input = {startUrls: [{url: arrays[index].website}]};

          const { data } = await axios.post(`https://api.apify.com/v2/acts/${actorId}/runs?token=${apiToken}`, input);
          console.log('data', data)
          const runId = data.data.id;
          
          let runStatus;
          while (runStatus !== 'SUCCEEDED' && runStatus !== 'FAILED' && runStatus !== 'ABORTED') {
            await new Promise(resolve => setTimeout(resolve, 5000));
            const { data } = await axios.get(`https://api.apify.com/v2/actor-runs/${runId}/?token=${apiToken}`);
            runStatus = data.data.status;
            console.log(`Status of actor run ${runId}: ${runStatus}`);
          }
  
          if (runStatus === 'SUCCEEDED') {
              const data = await axios.get(`https://api.apify.com/v2/actor-runs/${runId}/dataset/items?token=${apiToken}`);
              const actorOutput = data.data;
              console.log('Actor output:', actorOutput);
              responses[index] = {'data': actorOutput}
          } else {
              console.log(`Actor run ${runId} failed with status ${runStatus}`);
          }
        }
        else
          responses[index] = 0;
      }
      responses.forEach(response => {
        if(response) {
          response.data = response.data.sort((a:any, b:any) => (a.count < b.count)? 1 : (a.count === b.count) ? 1 : -1);
          return response
        }
      });
      await Promise.all(responses.map(response => {
        const resultArray: OutputData[] = [];
        const regex = /[®™]/g;
        response.data?.map((item: InputData) => {
          item.term = item.term.toUpperCase();
          item.term = item.term.replace(/\s/g, '');
          return item;
        })
        response.data?.map((item: InputData) => {
          if(resultArray.find((object: OutputData) => {
            if(object.term.replace(regex, '') === item.term.replace(regex, '')){
              object.count +=item.count;
              return true;
            }
            else return false;
          })) {} else {
            resultArray.push({count: item.count, term: item.term, isCommon: null, alternative: null, isMoreThanOne: null, recommend: null});
          }
        });
        resultArray.map((item: OutputData) => {
          console.log("item.term", item.term.replace(regex, '').toLowerCase());
          if(dictionaryList.includes(item.term.replace(regex, '').toLowerCase())) {
            console.log("asdfads")
            item.isCommon = true;
            const longTermArray: string[] = [];
            response.data.forEach((inputItem: InputData)=>{
              if(inputItem.term === item.term)
                longTermArray.push(inputItem.longTerm)
            })
            console.log('longterm', longTermArray)
            const mostPopular = findMostPopularString(longTermArray);
            if(mostPopular) item.alternative = mostPopular
            if(mostPopular?.includes(" ")) {
              item.isMoreThanOne = true;
              item.recommend = true;
            }
          }
          return item;
        })
        if(response.data)
          response.data = resultArray;
        return response;
      }))
      if(responses[0])
        results.push({title: arrays[0].title, company: arrays[0].company, website: arrays[0].website, brand: arrays[0].brand, brandLists: responses[0].data});
      if(responses[1])
        results.push({title: arrays[1].title, company: arrays[1].company, website: arrays[1].website, brand: arrays[1].brand, brandLists: responses[1].data});
      if(responses[2])
        results.push({title: arrays[2].title, company: arrays[2].company, website: arrays[2].website, brand: arrays[2].brand, brandLists: responses[2].data});
    } catch(err) {
      console.log(err)
    }
    await Promise.all(results.map(async (data: any) => {
      let brandLists = data.brandLists;
      brandLists.sort((a:any, b:any) => (a.count < b.count)? 1 : (a.count === b.count) ? 1 : -1);
      const regex = /[®™]/g;
      const checkExists = (brandLists: any, brand: string) => {
        return brandLists.some((item: any) => item.term.replace(regex, '') === brand.toUpperCase())
      }
      if(checkExists(brandLists, data.brand)) {
        const index = brandLists.findIndex((item: any)=> item.term.replace(regex, '') === data.brand.toUpperCase());
        const item = brandLists[index];
        brandLists.splice(index, 1);
        brandLists.splice(0, 0, item);
      } else {
        const query = `${data.brand} inurl:${data.website}`;
        const apiUrl = "https://api.apify.com/v2/acts/apify~google-search-scraper/run-sync-get-dataset-items?token=apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1";
        const response = await axios.post(apiUrl, {queries: query});
        console.log("response", response.data);
        const number = response.data;
        const totalNumber = number[0].resultsTotal;
        console.log("number", number[0], "totalNumber", totalNumber)
        const item = {"term": data.brand, "count": totalNumber, "alternative": null, "isCommon": null, "isMoreThanOne": null, "recommend": null};
        brandLists.splice(0, 0, item);
      }
      for (const [index, brand] of brandLists.entries()) {
        if (index === 0) {
          const query = `${brand.term} site:linkedin.com`;
          const apiUrl = "https://api.apify.com/v2/acts/parse~google-search/run-sync-get-dataset-items?token=apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1";
          const linkedinResponse = await axios.post(apiUrl, {query: query});
          console.log("linkedin count result", linkedinResponse.data);
          const linkedinCount = linkedinResponse.data[0].search_result_count;
          // const apiUrlForTrends = "https://api.apify.com/v2/acts/emastra~google-trends-scraper/run-sync-get-dataset-items?token=apify_api_RAQ3OtcSEPg2FdR0dLg6onHzTreXDY3KTfO1";
          // const trendsResponse = await axios.post(apiUrlForTrends, {searchTerms: [brand.term]});
          // console.log("trend search result", trendsResponse.data);
          brand.linkedinCount = linkedinCount;
          // brand.trends = trendsResponse.data[0];
        }
      }
      brandLists.forEach((brand: any, index: any) => {
        brand.confidence = index===0?'primary':'sub-brand';
        brand.include = index===0?true:false;
      });

      const newData = {"brand": data.brand, "title": data.title, "company": data.company, "website": data.website, "brandLists": brandLists};
      return newData;
    }))

    console.log("results", results)
    setSearchResult(results);
    // await axios.post(API_URL, results)
    setIsLoaded(true);
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
  }, []);
  
  const handleClick = () => {
    navigate(selectedProject ? `/projects/${selectedProject.id}/search/result` : '/', {state:results});
  }
  return (
    <AuthenticatedLayout sidebar={false}>
      <VStack spacing={6} align="flex-start">
        <SoftCard w="100%">
          <PageHeading title="Set-up Companies: Confirmation" icon={MdPending} />
        </SoftCard>
        {isLoaded?
        <Center w="100%" h="h-[calc(100vh-80px)]">
          <NextButton text='Search Complete - Next Step' isLoaded={isLoaded} onSubmit={handleClick}></NextButton>
        </Center>
        :
        <Center w="100%" h="h-[calc(100vh-80px)]">
          <VStack spacing={6}>
          <Card align='center'>
            <CardHeader>
              <MyTimer expiryTimestamp={time} />
            </CardHeader>
            <CardBody>
              <Progress size='xs' isIndeterminate></Progress>
              <Text mt={5}>Companies submitted. We are now searching for brands on the company websites you have entered.
                  This may take up to five minutes.</Text>
            </CardBody>
            <CardFooter>
            <NextButton text='Currently Searching..' isLoaded={isLoaded} onSubmit={handleClick}></NextButton>
            </CardFooter>
          </Card>
          </VStack>
        </Center>
        }
      </VStack>
    </AuthenticatedLayout>
  )
})
